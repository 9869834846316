import * as React from 'react';
import styled, { css, useTheme } from 'styled-components';

import useCurrentUser from 'hooks/useCurrentUser';
import { Timestamp, Switch } from 'components/common';
import { GLCheckboxWithScope, ConfirmDialog } from 'common/components';
import { EmptyList, TD } from 'security-app/components/common';
import { usePagination, useSelectedRows, useSelectedRowsDispatch, useModalContext } from 'common/contexts';
import { useToggleSigmaRule } from 'security-app/hooks/useSigmaAPI';
import type { SigmaRuleListAPIType } from 'security-app/hooks/api/sigmaAPI.types';

import RowActions from './RowActions';

const RuleLevel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-transform: capitalize;
`;

const LevelIndicator = styled.div<{ $levelColor: string }>(
  ({ $levelColor }) => css`
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 100px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    line-height: 0.85;

    background-color: ${$levelColor};
    filter: saturate(0.7) brightness(1.3);
  `,
);

type Props = {
  displayRowActions?: boolean;
  rules: SigmaRuleListAPIType[];
  visibleColumns: Array<string>;
  renderRowTitle: (rule: SigmaRuleListAPIType, editRule: (rule: SigmaRuleListAPIType) => void) => React.ReactNode;
};

function ListRows({ rules, visibleColumns, renderRowTitle, displayRowActions = true }: Props) {
  const { permissions } = useCurrentUser();
  const { colors } = useTheme();
  const { toggleSigmaRule } = useToggleSigmaRule();
  const { setModal, setEntity } = useModalContext();
  const [ruleToBeToggled, setRuleToBeToggled] = React.useState<SigmaRuleListAPIType>();

  const localPagination = usePagination();
  const selectedRules = useSelectedRows();
  const selectedRulesDispatch = useSelectedRowsDispatch();

  const levelColorMap = {
    critical: colors.brand.primary,
    high: colors.brand.primary,
    medium: '#F98A3A',
    low: colors.variant.success,
    informational: colors.variant.info,
  };

  const canManageRules = React.useMemo(
    () => permissions.includes('sigma_rule:edit') || permissions.includes('*'),
    [permissions],
  );

  const onSelect = (rule: SigmaRuleListAPIType) => (e: React.BaseSyntheticEvent) => {
    if (e.target.checked) {
      selectedRulesDispatch({ type: 'add', payload: [rule] });
    } else {
      selectedRulesDispatch({ type: 'remove', payload: [rule] });
    }
  };

  const isSelected = (rule: SigmaRuleListAPIType) => !!selectedRules.find((sRule: SigmaRuleListAPIType) => rule.id === sRule.id);

  const editRule = (rule: SigmaRuleListAPIType) => {
    setEntity(rule);
    setModal('MANUAL-ADD');
  };

  const toggleRule = (rule: SigmaRuleListAPIType = ruleToBeToggled) => {
    toggleSigmaRule(
      { ruleId: rule.id, enable: !rule.enabled },
      { onSuccess: () => selectedRulesDispatch({ type: 'update', payload: rule }) },
    );

    setRuleToBeToggled(undefined);
  };

  const onToggleRule = (rule: SigmaRuleListAPIType) => {
    if (rule.is_hunting_query && !rule.enabled) {
      setRuleToBeToggled(rule);
    } else {
      toggleRule(rule);
    }
  };

  const columnIsVisible = (columnName: string) => visibleColumns.includes(columnName);

  return rules.length < 1 ? (
    <EmptyList
      query={localPagination.query}
      filters={localPagination.filters}
      noSearchResultText="No Rules found for the provided criteria"
      noEntitiesExist="Currently there are no Rules to list"
      colSpan={8}
    />
  ) : (
    <>
      {rules.map((rule: SigmaRuleListAPIType) => (
        <tr key={`rule-row-${rule.id}`} data-testid="rule-row">
          <TD><GLCheckboxWithScope entity={rule} hideCheckbox={!canManageRules} isSelected={isSelected} onSelect={onSelect} /></TD>
          {columnIsVisible('title') && <TD data-testid="rule-name">{renderRowTitle(rule, editRule)}</TD>}
          {columnIsVisible('level') && (
            <TD>
              <RuleLevel>
                <LevelIndicator $levelColor={levelColorMap[rule.level]}>
                  {rule.level === 'critical' ? '!' : ''}
                </LevelIndicator>
                <div>{rule.level}</div>
              </RuleLevel>
            </TD>
          )}
          {columnIsVisible('status') && <TD style={{ textTransform: 'capitalize' }}>{rule.status}</TD>}
          {columnIsVisible('last_run') && (
            <TD>
              <Timestamp dateTime={rule.last_run} />
            </TD>
          )}
          {columnIsVisible('updated_at') && (
            <TD>
              <Timestamp dateTime={rule.updated_at} />
            </TD>
          )}
          {columnIsVisible('enabled') && (
            <TD>
              <Switch
                aria-label={`${rule.enabled ? 'Disable' : 'Enable'} rule`}
                onChange={() => onToggleRule(rule)}
                checked={rule.enabled}
                className="center"
                disabled={!canManageRules}
              />
            </TD>
          )}
          {(canManageRules && displayRowActions) ? <TD><RowActions rule={rule} editRule={editRule} /></TD> : <TD />}
          {(ruleToBeToggled?.id === rule.id) && (
            <ConfirmDialog
              show
              onConfirm={() => toggleRule()}
              confirmText="Confirm"
              onCancel={() => setRuleToBeToggled(undefined)}
              title="Are you sure?"
              type="warning"
            >
              <>
                <p>
                  Warning! The rule you are attempting to enable is designated as a &quot;hunting query&quot;.
                  These rules are typically designed to be run on an ad-hoc basis via the &quot;Search Logs&quot;
                  feature, rather than enabled for background detection. Running rules such as this will
                  typically generate many false positive events, and could possibly cause performance issues.
                </p>
                <p>Are you sure you would like to proceed?</p>
              </>
            </ConfirmDialog>
          )}
        </tr>
      ))}
    </>
  );
}

export default ListRows;
