import * as React from 'react';

import { SelectedRowsProvider, PaginationProvider } from 'common/contexts';
import type { Pack } from 'illuminate/types';
import IlluminatePacks from 'illuminate/components/illuminate/IlluminatePacks';
import { Alert } from 'components/bootstrap';
import { Spinner } from 'components/common';

import useThreatRelatedIlluminatePacks from './useThreatRelatedIlluminatePacks';

const IlluminatePacksEnable = () => {
  const { allPacks, notEnabledPacks, isInitialLoading } = useThreatRelatedIlluminatePacks();

  if (isInitialLoading) {
    return <Spinner />;
  }

  return (
    <SelectedRowsProvider<Pack>>
      <PaginationProvider initFilters={{ tags: 'attack' }}>
        {allPacks.length > 0 && notEnabledPacks.length === 0 ? (
          <Alert bsStyle="success">All related Illuminate Packs have been activated</Alert>
        ) : (
          <IlluminatePacks
            includeDepPacks
            showSearchForm={false}
            showLoadingSpinner
            showInstallSuccessAlert={false}
            onInstallFinished={(setLocalPagination) => setLocalPagination((cur) => ({ ...cur, page: 1 }))}
          />
        )}
      </PaginationProvider>
    </SelectedRowsProvider>
  );
};

export default IlluminatePacksEnable;
