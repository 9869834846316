import * as React from 'react';

import useCurrentUser from 'hooks/useCurrentUser';
import useScopePermissions from 'hooks/useScopePermissions';
import { ColumnSort, ColumnFilter, TH } from 'security-app/components/common';
import { GLCheckbox } from 'common/components';
import type { FilterData, SigmaRulesFilterOptionData } from 'security-app/components/common/Filters/ColumnFilter.types';
import { usePagination, useSetPagination, useSelectedRows, useSelectedRowsDispatch } from 'common/contexts';
import { useFetchSigmaRulesFilterOptions } from 'security-app/hooks/useSigmaAPI';
import type { SigmaRuleListAPIType } from 'security-app/hooks/api/sigmaAPI.types';

type Props = {
  displayRowActions: boolean;
  rules: SigmaRuleListAPIType[];
  visibleColumns: Array<string>;
};

function ListHeader({ rules, visibleColumns, displayRowActions }: Props) {
  const { permissions } = useCurrentUser();
  const { checkPermissions } = useScopePermissions();
  const pagination = usePagination();
  const setPagination = useSetPagination();
  const [filterOptions, setFilterOptions] = React.useState<SigmaRulesFilterOptionData>({ id: null });

  const canManageRules = React.useMemo(
    () => permissions.includes('sigma_rule:edit') || permissions.includes('*'),
    [permissions],
  );

  const selectedRules = useSelectedRows();
  const selectedRulesDispatch = useSelectedRowsDispatch();

  const { fetchSigmaRulesFilterOptions, fetchingSigmaRulesFilterOptions } = useFetchSigmaRulesFilterOptions();

  React.useMemo(async () => {
    const filters = await fetchSigmaRulesFilterOptions({ fields: ['level', 'status', 'enabled'] });

    setFilterOptions(filters);
  }, [fetchSigmaRulesFilterOptions]);

  const levelOptions = React.useMemo(() => {
    if (!filterOptions?.level) return [];
    const options = [];

    filterOptions.level.forEach((level: string) => {
      options.push({ label: level.toString(), value: level.toString(), type: typeof level });
    });

    return options;
  }, [filterOptions]);

  const statusOptions = React.useMemo(() => {
    if (!filterOptions?.status) return [];
    const options = [];

    filterOptions.status.forEach((status: string) => {
      options.push({ label: status.toString(), value: status.toString(), type: typeof status });
    });

    return options;
  }, [filterOptions]);

  const enabledOptions = React.useMemo(() => {
    if (!filterOptions?.enabled) return [];
    const options = [];

    filterOptions.enabled.forEach((enabled: boolean) => {
      options.push({ label: enabled.toString(), value: enabled.toString(), type: typeof enabled });
    });

    return options;
  }, [filterOptions]);

  const applySort = (field: string, direction: string) => {
    setPagination({ ...pagination, orderBy: field, direction });
  };

  const applyFilters = (filterKey: string, filterValues: FilterData[]) => {
    const auxFilters = { ...pagination.filters, [filterKey]: filterValues };
    if (!filterValues || filterValues.length === 0) delete auxFilters[filterKey];

    setPagination({ ...pagination, filters: auxFilters });
  };

  const selectAll = (e: React.BaseSyntheticEvent) => {
    const auxRules = rules.filter(checkPermissions);

    if (e.target.checked) {
      selectedRulesDispatch({ type: 'add', payload: auxRules });
    } else {
      selectedRulesDispatch({ type: 'remove', payload: auxRules });
    }
  };

  const allSelected = React.useMemo(
    () =>
      rules.length > 0 &&
      selectedRules.length > 0 &&
      rules.every(
        (rule: SigmaRuleListAPIType) => !!selectedRules.find((sRule: SigmaRuleListAPIType) => rule.id === sRule.id),
      ),
    [rules, selectedRules],
  );

  const columnIsVisible = (columnName: string) => visibleColumns.includes(columnName);

  return (
    <>
      {!fetchingSigmaRulesFilterOptions && (
        <thead>
          <tr>
            {canManageRules ? (
              <TH style={{ width: 32 }}>
                <GLCheckbox checked={allSelected} onChange={selectAll} title="Select all sigma rules" />
              </TH>
            ) : (
              <TH />
            )}
            {columnIsVisible('title') && (
              <TH>
                <ColumnSort
                  field="parsed_rule.title"
                  orderBy={pagination.orderBy}
                  direction={pagination.direction}
                  onSort={applySort}>
                  Rule
                </ColumnSort>
              </TH>
            )}
            {columnIsVisible('level') && (
              <TH>
                <ColumnSort
                  field="parsed_rule.level"
                  orderBy={pagination.orderBy}
                  direction={pagination.direction}
                  onSort={applySort}>
                  <ColumnFilter
                    filterBy="level"
                    type="text"
                    filterData={levelOptions}
                    appliedFilters={pagination.filters}
                    onClose={applyFilters}>
                    Level
                  </ColumnFilter>
                </ColumnSort>
              </TH>
            )}
            {columnIsVisible('status') && (
              <TH>
                <ColumnSort
                  field="parsed_rule.status"
                  orderBy={pagination.orderBy}
                  direction={pagination.direction}
                  onSort={applySort}>
                  <ColumnFilter
                    filterBy="status"
                    type="text"
                    filterData={statusOptions}
                    appliedFilters={pagination.filters}
                    onClose={applyFilters}>
                    Status
                  </ColumnFilter>
                </ColumnSort>
              </TH>
            )}
            {columnIsVisible('last_run') && (
              <TH>
                <ColumnSort
                  field="last_run"
                  orderBy={pagination.orderBy}
                  direction={pagination.direction}
                  onSort={applySort}>
                  <ColumnFilter
                    filterBy="last_run"
                    type="date"
                    filterData={[]}
                    appliedFilters={pagination.filters}
                    onClose={applyFilters}>
                    Last Run
                  </ColumnFilter>
                </ColumnSort>
              </TH>
            )}
            {columnIsVisible('updated_at') && (
              <TH>
                <ColumnSort
                  field="updated_at"
                  orderBy={pagination.orderBy}
                  direction={pagination.direction}
                  onSort={applySort}>
                  <ColumnFilter
                    filterBy="updated_at"
                    type="date"
                    filterData={[]}
                    appliedFilters={pagination.filters}
                    onClose={applyFilters}>
                    Last Update
                  </ColumnFilter>
                </ColumnSort>
              </TH>
            )}
            {columnIsVisible('enabled') && (
              <TH style={{ width: 65 }}>
                <ColumnSort
                  field="enabled"
                  orderBy={pagination.orderBy}
                  direction={pagination.direction}
                  onSort={applySort}>
                  <ColumnFilter
                    filterBy="enabled"
                    type="text"
                    filterData={enabledOptions}
                    appliedFilters={pagination.filters}
                    onClose={applyFilters}>
                    Enabled
                  </ColumnFilter>
                </ColumnSort>
              </TH>
            )}
            {displayRowActions && <TH style={{ width: 32 }} />}
          </tr>
        </thead>
      )}
    </>
  );
}

export default ListHeader;
