import styled, { css } from 'styled-components';

import { Col } from 'components/bootstrap';

const ColContentStyles = css(
  ({ theme }) => css`
    background-color: ${theme.colors.global.contentBackground};
    border: 1px solid ${theme.colors.variant.lighter.default};
    margin-bottom: ${theme.spacings.xs};
    margin-left: ${theme.spacings.xs};
    margin-right: ${theme.spacings.xs};
    border-radius: 4px;
    display: table-cell;
    max-height: 100%;
  `,
);

const StyledCol = styled(Col)`
  padding: 10px;
  ${ColContentStyles}
  .sticky {
    top: 20px;
    position: sticky;
  }
`;

export default StyledCol;
