export type Bundle = {
  bundle_id: string;
  title: string;
  enabled: boolean;
  version: string;
};

export type Bundles = Array<Bundle>;

export type OverrideType = {
  id: string;
  title: string;
  description: string;
  name: string;
  custom_error_ttl_enabled: boolean;
  custom_error_ttl: number | null;
  custom_error_ttl_unit: number | null;
  content_pack: string | null;
  config: {
    type: string;
    path: string;
    separator: string;
    quotechar: string;
    key_column: string;
    value_column: string;
    check_interval: number;
    case_insensitive_lookup: boolean;
  };
};

export type OverrideData = {
  overrides: {
    [key: string]: string;
  };
};

export type Pack = {
  category: string;
  description: string;
  enabled: boolean;
  version: string;
  input_types: string[];
  is_compatible: boolean;
  is_core: boolean;
  is_new: boolean;
  pack_id: string;
  release_notes: string;
  requirements: string[];
  subcategory: string;
  tags: string[];
  title: string;
  type: string;
  update_available: boolean;
  version_requirements: string[];
  included_in_bundle: boolean;
};

export type PacksIndexAPIType = {
  page: number;
  per_page: number;
  total: number;
  grand_total: number;
  count: number;
  packs: Pack[];
};

export type PackFiltersType = {
  is_new?: boolean;
  update_available?: boolean;
  query?: string;
  category?: string[];
  subcategory?: string[];
  tags?: string[];
  type?: string;
  include_deps?: boolean;
};

export type Packs = Array<Pack>;

export type PacksSummary = {
  cached_version: string;
  current_version: string;
  new_packs_available: boolean;
  updated_packs_available: boolean;
};

export type IlluminateStatus = {
  illuminate_enabled: boolean;
  deprecated_illuminate_enabled: boolean;
  upload_enabled: boolean;
};

export type NewBundleType = {
  version: string;
  is_new_version: boolean;
};

export type HubBundleType = {
  version: string;
  title: string;
  published_date: string;
  size_bytes: number;
  installed: boolean;
  prerelease: boolean;
  minimum_graylog_version: string[];
  is_compatible: boolean;
};

export type HubReleaseNotes = {
  release_notes: string;
  version: string;
};

type InstallProgressItem = {
  install_in_progress: boolean;
  complete: boolean;
  successful: boolean;
  title: string;
  description: string;
};

export type InstallStatus = {
  id: string;
  created_at: string;
  updated_at: string;
  illuminate_version: string;
  install_in_progress: boolean;
  install_progress: InstallProgressItem[];
  pack_titles: string[];
  spotlight_pack_titles: string[];
  disable_packs: boolean;
  previous_install?: {
    successful: boolean;
    title: string;
    description: string;
    disable_packs: boolean;
    illuminate_version: string;
    installed_at: string;
    pack_titles: string[];
    spotlight_pack_titles: string[];
  };
};

export type FilterOptions = {
  category: string[];
  input_types: string[];
  tags: string[];
};

export const TYPE_OPTIONS = [
  { label: 'Logs', value: 'PROCESSING' },
  { label: 'Content', value: 'SPOTLIGHT' },
];

export enum PackTypes {
  PROCESSING = 'PROCESSING',
  SPOTLIGHT = 'SPOTLIGHT',
};

export type IlluminateHubFilterData = {
  [key: string]: string[];
};
