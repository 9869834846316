import __request__ from 'routing/request';
interface InputSummary {
    readonly creator_user_id: string;
    readonly node: string;
    readonly name: string;
    readonly created_at: string;
    readonly global: boolean;
    readonly attributes: {
        readonly [_key: string]: Object;
    };
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly content_pack: string;
    readonly static_fields: {
        readonly [_key: string]: string;
    };
}
interface InputReferences {
    readonly pipeline_refs: InputReference[];
    readonly input_id: string;
    readonly stream_refs: InputReference[];
}
interface InputDiagnostics {
    readonly stream_message_count: StreamMessageCount[];
}
interface InputsList {
    readonly total: number;
    readonly inputs: InputSummary[];
}
interface InputCreated {
    readonly id: string;
}
interface StreamMessageCount {
    readonly stream_name: string;
    readonly stream_id: string;
    readonly count: number;
}
interface InputReference {
    readonly name: string;
    readonly id: string;
}
interface InputCreateRequest {
    readonly node: string;
    readonly configuration: {
        readonly [_key: string]: Object;
    };
    readonly global: boolean;
    readonly title: string;
    readonly type: string;
}
/**
 * Get all inputs
 */
export function list(): Promise<InputsList> {
    return __request__('GET', '/system/inputs', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Launch input on this node
 */
export function create(JSONbody: InputCreateRequest): Promise<InputCreated> {
    return __request__('POST', '/system/inputs', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get diagnostic information of a single input
 */
export function diagnostics(inputId: string): Promise<InputDiagnostics> {
    return __request__('GET', `/system/inputs/diagnostics/${inputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Returns any streams or pipeline that reference the given input
 */
export function getReferences(inputId: string): Promise<InputReferences> {
    return __request__('GET', `/system/inputs/references/${inputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Terminate input on this node
 */
export function terminate(inputId: string): Promise<void> {
    return __request__('DELETE', `/system/inputs/${inputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get information of a single input on this node
 */
export function get(inputId: string): Promise<InputSummary> {
    return __request__('GET', `/system/inputs/${inputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update input on this node
 */
export function update(JSONbody: InputCreateRequest, inputId: string): Promise<InputCreated> {
    return __request__('PUT', `/system/inputs/${inputId}`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
