import __request__ from 'routing/request';
interface PaginatedResponse_UserOverviewDTO {
    readonly [_key: string]: Object;
}
interface PaginationParameters {
    readonly per_page: number;
    readonly query: string;
    readonly page: number;
    readonly sort_by: string;
    readonly order: string;
}
interface PaginatedResponse_AuthzRoleDTO {
    readonly [_key: string]: Object;
}
type stringArray = string[];
interface PaginatedResponse_TeamDTO {
    readonly [_key: string]: Object;
}
interface TeamDTO {
    readonly auth_service_id: string;
    readonly roles: string[];
    readonly auth_service_uid: string;
    readonly name: string;
    readonly description: string;
    readonly id: string;
    readonly type: 'INTERNAL' | 'SYNCED';
    readonly users: string[];
}
interface PaginatedResponse_EntityDescriptor {
    readonly [_key: string]: Object;
}
/**
 * Create a new team
 */
export function create(JSONBody?: TeamDTO): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.security/teams', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of all teams
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getList(sort: 'name' | 'description' = 'name', page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_TeamDTO> {
    return __request__('GET', '/plugins/org.graylog.plugins.security/teams', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of teams containing a user
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getListForUsername(sort: 'name' | 'description' = 'name', userId?: string, page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_TeamDTO> {
    return __request__('GET', `/plugins/org.graylog.plugins.security/teams/member/${userId}`, null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Return shares for a team
 */
export function get(teamId: string, paginationparameters?: PaginationParameters, capability?: string, entity_type?: string): Promise<PaginatedResponse_EntityDescriptor> {
    return __request__('GET', `/plugins/org.graylog.plugins.security/teams/shares/${teamId}`, paginationparameters, { 'capability': capability, 'entity_type': entity_type }, {
        'Accept': ['application/json']
    });
}
/**
 * Get a single team
 */
export function getByteamId(teamId?: string): Promise<TeamDTO> {
    return __request__('GET', `/plugins/org.graylog.plugins.security/teams/${teamId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update a team
 */
export function update(teamId?: string, JSONBody?: TeamDTO): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.security/teams/${teamId}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a team
 */
export function remove(teamId?: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.security/teams/${teamId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Add a members to a team
 */
export function addMember(teamId?: string, userIds?: stringArray): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.security/teams/${teamId}/members`, userIds, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of users assigned to a team
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getListOfUsers(sort: 'name' | 'full_name' | 'username' = 'username', teamId?: string, page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_UserOverviewDTO> {
    return __request__('GET', `/plugins/org.graylog.plugins.security/teams/${teamId}/members`, null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Remove a member from a team
 */
export function removeMember(teamId?: string, userId?: string): Promise<unknown> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.security/teams/${teamId}/members/${userId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a paginated list of roles assigned to a team
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getListOfRoles(sort: 'name' | 'description' = 'name', teamId?: string, page: number = 1, per_page: number = 50, query?: string, order: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_AuthzRoleDTO> {
    return __request__('GET', `/plugins/org.graylog.plugins.security/teams/${teamId}/roles`, null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Add a user to a set of teams
 */
export function addMemberToTeams(userId?: string, teams?: stringArray): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.security/teams/${userId}/teams`, teams, {}, {
        'Accept': ['application/json']
    });
}
