import __request__ from 'routing/request';
interface ImportAllRulesRequest {
    readonly stream_categories: string[];
    readonly search_within_ms: number;
    readonly cron_expression: string;
    readonly execute_every_ms: number;
    readonly use_cron_scheduling: boolean;
    readonly streams: string[];
    readonly cron_timezone: string;
    readonly id: string;
    readonly filters: UsedSearchFilter[];
    readonly remediation_steps: string;
    readonly notifications: string[];
}
interface SigmaRepoFile {
    readonly path: string;
    readonly product: string;
    readonly name: string;
    readonly rule_description: string;
    readonly id: string;
    readonly type: string;
    readonly title: string;
    readonly tags: string;
}
interface UsedSearchFilter {
}
interface BulkRuleRequest {
    readonly ids: string[];
}
interface RepositoryCreateUpdateResponse {
    readonly success_count: number;
    readonly failure_count: number;
    readonly errors: string[];
}
interface PaginatedResponse_GitRepositoryResponse {
    readonly [_key: string]: Object;
}
type SigmaRepoFileArray = SigmaRepoFile[];
interface CreateUpdateRepoRequest {
    readonly name: string;
    readonly directories: string[];
    readonly uri: string;
    readonly branch: string;
}
/**
 * List added Git repositories
 * @param sort The field to sort the result on
 * @param direction The sort direction
 */
export function list(sort: 'name' = 'name', page: number = 1, per_page: number = 15, query?: string, direction: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_GitRepositoryResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/sigma/repositories', null, { 'page': page, 'per_page': per_page, 'query': query, 'sort': sort, 'direction': direction }, {
        'Accept': ['application/json']
    });
}
/**
 * Add a new Git repository
 */
export function create(CreateRepoRequest?: CreateUpdateRepoRequest): Promise<RepositoryCreateUpdateResponse> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/sigma/repositories', CreateRepoRequest, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Bulk delete repositories
 */
export function bulkDeleteRepos(RepositoryIDs?: BulkRuleRequest): Promise<unknown> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/sigma/repositories/bulk/delete', RepositoryIDs, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Add the default SigmaHQ Git repository
 */
export function createDefault(): Promise<RepositoryCreateUpdateResponse> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/sigma/repositories/default', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Import all rules in a repository
 */
export function importAll(ImportAllRulesRequest: ImportAllRulesRequest): Promise<unknown> {
    return __request__('PUT', '/plugins/org.graylog.plugins.securityapp/sigma/repositories/import_all', ImportAllRulesRequest, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Refresh all rules of an existing repository
 */
export function refreshRepo(id: string): Promise<RepositoryCreateUpdateResponse> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/sigma/repositories/refresh/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List rules in a repository directory
 */
export function getRulesFromRepo(id: string, path?: string, query?: string): Promise<SigmaRepoFileArray> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/sigma/repositories/${id}`, null, { 'path': path, 'query': query }, {
        'Accept': ['application/json']
    });
}
/**
 * Update an existing repository
 */
export function updateRepo(id: string, UpdateRepoRequest?: CreateUpdateRepoRequest): Promise<RepositoryCreateUpdateResponse> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/sigma/repositories/${id}`, UpdateRepoRequest, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an existing repository
 */
export function deleteRepo(id: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/sigma/repositories/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
