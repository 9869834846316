import { useCallback } from 'react';

import { useFetchAllLatestBundlePacks } from 'illuminate/hooks/usePacksAPI';
import { PackTypes } from 'illuminate/types';
import type { Pack, IlluminateHubFilterData } from 'illuminate/types';
import type { PacksIndexAPIType, PackType, FilterOptions } from 'illuminate/components/content-hub/types';

import TagsFilterComponent from './packs-list/tags-filter-component';
import { ATTRIBUTES } from './packs-list/constants';

type DeserializePacksArgs = [FilterOptions, PacksIndexAPIType];

export const deserializePacks = ([{ category, input_types, tags }, { page, per_page, total, count, packs }]: DeserializePacksArgs ) => {
  const auxAttributes = [...ATTRIBUTES];

  if (category.length) auxAttributes.push({
    id: 'category',
    title: 'Category',
    type: 'STRING',
    filterable: true,
    filter_options: category.map((opt: string) => ({ value: opt, title: opt })),
  });

  if (tags.length) auxAttributes.push({
    id: 'tags',
    title: 'Tags',
    type: 'STRING',
    filterable: true,
    filter_options: tags.map((opt: string) => ({ value: opt, title: opt })),
    filter_component: TagsFilterComponent,
    hidden: true,
  });

  if (input_types.length) auxAttributes.push({
    id: 'input_types',
    title: 'Input Types',
    type: 'STRING',
    filterable: true,
    filter_options: input_types.map((opt: string) => ({ value: opt, title: opt })),
    hidden: true,
  });

  return ({
    attributes: auxAttributes,
    list: packs.map((pack: PackType) => ({ ...pack, id: pack.pack_id })) ?? [],
    pagination: { total, page, per_page, count },
  });
};

export const buildEnableDisablePayload = (selectedPacks: Array<Pack>) => {
  const requestObject = {
    processing_pack_ids: [],
    spotlight_pack_ids: [],
  };

  selectedPacks.forEach((pack: Pack) => {
    if (pack.type === PackTypes.PROCESSING) {
      requestObject.processing_pack_ids.push(pack.pack_id);
    } else if (pack.type === PackTypes.SPOTLIGHT) {
      requestObject.spotlight_pack_ids.push(pack.pack_id);
    }
  });

  return requestObject;
};

export const useGetRequirements = () => {
  const { fetchingAllPacks, allPacks } = useFetchAllLatestBundlePacks();

  const getRequiredPacks = useCallback((inPack: Pack) => (
    allPacks.filter((pack: Pack) => inPack.requirements.includes(pack.pack_id))
  ), [allPacks]);

  return {
    getRequiredPacks,
    fetchingAllPacks,
    allPacks,
  };
};

export function buildIlluminateQuery(filters: IlluminateHubFilterData) {
  if (!filters || Object.keys(filters).length === 0) return '';

  const fDataFormatted = (fData: string[]) => {
    const strFData = fData.map((value) => value).join(',');

    return `${strFData}`;
  };

  return Object.entries(filters)
    .map(([key, fData]: [string, string[]]) => `${key}=${fDataFormatted(fData)}`)
    .join('&');
}
