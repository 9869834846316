import * as React from 'react';
import styled from 'styled-components';

import IlluminatePackRequirement from 'illuminate/components/illuminate/IlluminatePackRequirement';
import StyledCol from 'security-app/components/common/StyledCol';
import { getRequirements } from 'illuminate/utils';
import type { Pack } from 'illuminate/types';

type Props = {
  activePack: Pack;
  packs: Pack[];
};

export const DescriptionCol = styled(StyledCol)`
  width: 60%;
`;

const RequiredVersion = styled.div`
  color: ${({ theme }) => theme.colors.variant.danger};
`;

const RequiredVersionList = styled.li`
  color: ${({ theme }) => theme.colors.variant.danger};
`;

const requiredVersionMessage = (requiredVersions: string[]): JSX.Element => {
  if (requiredVersions.length === 1) {
    return <RequiredVersion>Requires Graylog version {requiredVersions[0]} or later</RequiredVersion>;
  }

  return (
    <>
      <RequiredVersion>Requires Graylog version:</RequiredVersion>
      <ul>
        {requiredVersions.map((version, index) => {
          if (index < requiredVersions.length - 1) {
            return <RequiredVersionList>- Version {version} or later</RequiredVersionList>;
          }

          return <RequiredVersionList>- Any version {version} or later</RequiredVersionList>;
        })}
      </ul>
      <br />
    </>
  );
};

const IlluminateDescription = ({ activePack, packs }: Props) => {
  const requirements = getRequirements({ selectedPack: activePack, packs });

  return (
    <DescriptionCol className="content">
      <div style={{ position: 'sticky', top: '20px' }}>
        <h3>{activePack.title}</h3>
        <br />
        {activePack.version_requirements.length === 0 ? null : requiredVersionMessage(activePack.version_requirements)}
        <div>{activePack.description}</div>
        <br />
        {requirements.length === 0 ? null : (
          <>
            <b>Requirements &nbsp;</b>
            <IlluminatePackRequirement requiredPacks={requirements} />
          </>
        )}
      </div>
      <br />
    </DescriptionCol>
  );
};

export default IlluminateDescription;
