import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import useThemes from 'theme/hooks/useThemes';
import { COLOR_SCHEME_LIGHT } from 'theme/constants';
import ColorSchemeContext from 'theme/ColorSchemeContext';

const PDFExportThemeProvider = ({ children }: React.PropsWithChildren) => {
  const { scTheme, colorScheme } = useThemes(COLOR_SCHEME_LIGHT, true);

  return (
    <ColorSchemeContext.Provider value={colorScheme}>
      <ThemeProvider theme={scTheme}>{children}</ThemeProvider>
    </ColorSchemeContext.Provider>
  );
};

export default PDFExportThemeProvider;
